/* UpgradePage.css */
.upgrade-container {
  background-color: #f8f9fa; /* Light background color for the container */
  padding: 2rem; /* Add padding to the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  h1,
  h2 {
    /* Darker text color for headings */
    color: #343a40;
  }
}

.feature-card {
  min-height: 200px; /* Adjust as needed */
  display: flex;
  flex-direction: column; /* Allows for consistent spacing */

  transition: transform 0.2s; /* Smooth transition for hover effects */
}

.feature-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

blockquote {
  background: #e9ecef; /* Light gray background for the quote */
  padding: 1rem; /* Add padding */
  border-left: 5px solid #007bff; /* Left border color */
}

.btn {
  margin-top: 1rem; /* Add margin above buttons */
}

.text-center {
  margin-bottom: 2rem; /* Space below centered elements */
}

.faq {
  padding: 1rem; /* Add padding to the FAQ section */
  background: #f1f1f1; /* Light gray background for FAQ */
  border-radius: 5px; /* Rounded corners */
}

/* UpgradePage.css */

.testimonials {
  background-color: #f8f9fa; /* Light background for contrast */
  padding: 2rem; /* Add padding */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.testimonial-card {
  margin-bottom: 1.5rem; /* Space between testimonials */
}

.blockquote {
  border-left: 5px solid #007bff; /* Left border color */
  padding-left: 1rem; /* Padding for the blockquote */
  font-style: italic; /* Italicize text for emphasis */
}
