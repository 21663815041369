/* Checkout.css */
.upgrade-container {
  background-color: #f0f4f8; /* A light background for a fresh look */
  border-radius: 10px; /* Rounded corners */
  padding: 2rem; /* Add padding */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: auto; /* Center the container */
  max-width: 600px; /* Limit the max width for a more compact look */
  h2 {
    color: #343a40; /* Darker heading color */
  }

  h3 {
    color: #007bff; /* Primary color for subheadings */
    margin-bottom: 1rem; /* Space below the subheading */
  }
}

.subscription-details {
  background-color: #ffffff; /* White background for contrast */
  padding: 1.5rem; /* Padding for the section */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 2rem; /* Space below this section */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.btn-primary {
  background-color: #007bff; /* Custom button color */
  border: none; /* Remove default border */
  transition: background-color 0.3s; /* Smooth transition */
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.alert {
  margin-bottom: 1rem; /* Space below alerts */
}

.feature-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
}

.feature-list li {
  padding: 0.5rem 0; /* Space between items */
  font-size: 1.1rem; /* Slightly larger text */
}

.form-group {
  margin-bottom: 1.5rem; /* Space between form groups */
}

input {
  border-radius: 5px; /* Rounded corners for inputs */
  border: 1px solid #ced4da; /* Custom border color */
  transition: border-color 0.3s; /* Smooth transition */
}

input:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light shadow on focus */
}
