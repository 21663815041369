.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-box {
  text-align: center;
  margin-top: 20px;
}

.bg-light-green {
  /* background-color: lightgreen;
   */
  background-color: rgba(144, 238, 144, 0.5);
}

.bg-light-red {
  /* background-color: lightcoral; */
  background-color: rgba(240, 128, 128, 0.5);
}
