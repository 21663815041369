.Calculator {
  min-height: 100vh;
}

.toast {
  position: 'fixed';
  left: '50px';
  z-index: 9999;
  top: 50;
}

.submit-btn {
  margin-top: 0px;
}
