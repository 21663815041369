.navbar {
  background-color: #282c34 !important; /* Background color */
}

.navbar .nav-link,
.navbar .navbar-brand {
  color: white !important; /* Change text color */
}

.navbar .nav-link:hover {
  color: #61dafb !important; /* Optional: Change hover color */
}

/* hamburger */
/* custom.css */
.navbar-light .navbar-toggler-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}

.navbar .navbar-text {
  color: white !important; /* Change text color */
}
